@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

// <link href="https://www.foo.com/wp-content/themes/independent-publisher/fonts/fontawesome-free-5-0-6/web-fonts-with-css/fontawesome-all.css" rel="stylesheet">

// $icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
// @import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';


html,
body {
  height: 100%;
  color-scheme: #06668E;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #fafafa;
}

// override material inline style to allow mini nav state
mat-sidenav[style] {
  visibility: visible !important;
}

.mat-drawer {
  transform: none !important;
}